<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="广告标题:" prop="Title">
              <Input placeholder="广告标题" v-model="params.Title" clearable />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Staus">
              <Select
                transfer
                v-model="params.Status"
                clearable
                placeholder="状态"
              >
                <Option
                  v-for="item in AdvertStatusEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem prop="Version" label="版本号:">
              <Select clearable v-model="params.Version" placeholder="版本号">
                <Option
                  v-for="item in PositionVersionEnum"
                  :key="item.ID"
                  :value="item.CN"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="广告位置:" prop="Position">
              <Select
                transfer
                v-model="params.Position"
                clearable
                placeholder="类型"
              >
                <Option
                  v-for="item in positionList"
                  :value="item.Code"
                  :key="item.ID"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="日期范围:" prop="TempDate">
              <Date-picker
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                placement="bottom-end"
                placeholder="日期范围"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="Dateupdate"
              ></Date-picker>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Advert_Edit'])"
            @click="handleAdd"
          >
            新增
          </Button>
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllAdvert', $data)"
          >
            更新缓存
            <i>{{ cacheUpdatedTime }}</i>
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="memberTable"
      :columns="ListOptions"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="SortChange"
    >
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['Advert_Search'])"
          @click="openDrawer(row, true)"
        >
          查看
        </Button>
        <Button
          class="ml2"
          size="small"
          type="success"
          v-if="$canAction(['Advert_Edit'])"
          @click="editopenDrawer(row, true)"
        >
          编辑
        </Button>
        <Button
          class="ml2"
          size="small"
          type="error"
          v-if="$canAction(['Advert_Del'])"
          @click="handleCancel(row)"
        >
          删除
        </Button>
      </template>
      <template slot="Index" slot-scope="{ row }">
        <InputNumber
          :min="0"
          :value="row.Index"
          :step="1"
          v-if="row.ProductType !== 20"
          @on-focus="IndexNumberFocus('Index', row)"
          @on-change="
            (val) => {
              IndexNumberChange('Index', val, row);
            }
          "
          @on-blur="IndexNumberBlur('Index', row)"
        />
      </template>
      <template slot="State" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10
              ? 'volcano'
              : row.Status === 30
              ? 'success'
              : row.Status === 20
              ? 'magenta'
              : row.State === -99
              ? 'red'
              : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="Image" slot-scope="{ row }">
        <Tooltip transfer placement="right">
          <Avatar
            :src="$setImagePrefix(row.ImgPath, 'product')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.ImgPath, 'product')"
              height="230"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <detail-drawer ref="DetailDrawer" />
    <EditDetailDrawer @on-save="loadList" ref="EditDetailDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import layout from "@/components/layout/index.vue";
import EditDetailDrawer from "./components/advert-edit.vue";
import DetailDrawer from "./components/advert-detail.vue";
import bizEnum from "@/libs/bizEnum";
export default {
  name: "AdvertManage",
  data() {
    return {
      tableHeight: 0,
      tableLoading: false,
      cacheUpdatedTime: "",
      isSearchMore: false,
      TempDate: [],
      AdvertPositionList: [],
      params: {
        Title: "",
        Position: "",
        Status: "10,20,30",
        Type: "",
        DateBegin: "",
        DateEnd: "",
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "Desc",
        Version: "",
      },
      ListOptions: [
        {
          title: "广告图片",
          key: "ImgPath",
          minWidth: 75,
          align: "center",
          slot: "Image",
        },
        {
          title: "排序",
          key: "Index",
          sortable: "custom",
          slot: "Index",
          align: "center",
          minWidth: 70,
        },
        {
          title: "广告标题",
          key: "Title",
          align: "left",
          minWidth: 250,
          ellipsis: true,
        },
        { title: "广告位置", key: "PositionCN", align: "left", minWidth: 120 },
        { title: "链接地址", key: "Url", align: "left", minWidth: 250 },
        {
          title: "状态",
          key: "StatusCN",
          slot: "State",
          align: "center",
          minWidth: 70,
        },
        {
          title: "创建时间",
          key: "CreateDate",
          sortable: "custom",
          align: "center",
          minWidth: 135,
        },
        // { title: '操作', key: 'action', slot: 'Action', align: 'center', fixed: 'right', minWidth: 145, maxWidth: 145 }
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  async created() {
    if (this.$route.meta.tableActionWidth) {
      this.ListOptions.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    if (this.AdvertPositionList.length === 0) {
      try {
        let res2 = await api.GetAllAdvertPosition({});
        if (res2.Data) {
          this.AdvertPositionList = res2.Data;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    }
    this.loadList();
    this.$getCacheUpdatedTime("AllAdvert", this);
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.loadList();
        this.$getCacheUpdatedTime("AllAdvert", this);
      }
    });
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.params.Page = 1;
        self.loadList();
      }
    };
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.params.Page = 1;
        self.loadList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    IndexNumberChange(key, val) {
      this.Default = val;
    },
    IndexNumberFocus(key, row) {
      this.Default = row[key];
    },
    async IndexNumberBlur(key, row) {
      const _this = this;
      let temp = _this.resObj.DataList.find((item) => {
        return item.ID === row.ID;
      });
      temp[key] = this.Default;
      try {
        _this.tableLoading = true;
        const res = await api.SaveAdvertIndexNum({
          id: row.ID,
          indexnum: this.Default,
        });
        if (res.Status === 100) {
          this.$Message.success("更新成功！");
          _this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
          _this.tableLoading = false;
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        _this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    // 日期变化方法
    Dateupdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    handleAdd() {
      this.$refs.EditDetailDrawer.$refs.levelForm.resetFields();
      this.$refs.EditDetailDrawer.isShow = true;
      this.$refs.EditDetailDrawer.isAdd = true;
      this.$refs.EditDetailDrawer.id = -1;
      this.$refs.EditDetailDrawer.obj = {};
    },
    openDrawer(row, isEdit) {
      this.$refs.DetailDrawer.id = row.ID;
      this.$refs.DetailDrawer.isShow = true;
      this.$refs.DetailDrawer.isEdit = isEdit;
      this.$refs.DetailDrawer.obj = row;
    },
    editopenDrawer(row, isEdit) {
      this.$refs.EditDetailDrawer.$refs.levelForm.resetFields();
      this.$refs.EditDetailDrawer.id = row.ID;
      this.$refs.EditDetailDrawer.obj = {
        ...row,
        Position: row.Position ? row.Position.split(",") : [],
      };
      this.$refs.EditDetailDrawer.isShow = true;
      this.$refs.EditDetailDrawer.isEdit = isEdit;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadList();
    },
    handleReset() {
      this.params.Name = "";
      this.params.DateBegin = "";
      this.params.DateEnd = "";
      this.params.Status = "10,30";
      this.TempDate = [];
      this.$refs.SearchForm.resetFields();
      this.loadList();
    },
    // 远程排序
    SortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadList();
    },
    showImport(row) {
      this.selectedRow = row;
      this.$Modal.confirm({
        title: "缓存更新确认",
        closable: true,
        loading: true,
        content: "确认更新缓存吗?",
        onOk: this.importCache,
        cancelText: "取消",
        onCancel: () => {
          this.$Modal.remove();
        },
      });
    },
    async importCache() {
      try {
        const res = await api.ImportRedis({
          key: "AllAdvert",
        });
        if (res.Status === 100) {
          this.closeModal();
          this.$Message.success("更新成功！");
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.closeModal();
        // TODO: 记录错误日志
      }
    },
    async loadList() {
      // const _this = this;
      this.tableLoading = true;
      try {
        const res = await api.GetAdvertList(this.params);
        // res.Data.DataList.forEach(item => {
        //   let pos = item.Position.split(',');
        //   item.PositionCN = pos.map(i => {
        //     let des = _this.AdvertPositionEnum.find(m => m.ID === Number(i));
        //     return des ? des.CN : '';
        //   }).join(',');

        //   item.PositionCode = item.PositionCode ? item.PositionCode.split(',') : [];
        // }
        // );
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (err) {
        this.tableLoading = false;
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    handleCancel(row) {
      const self = this;
      this.$Modal.confirm({
        title: "删除确认",
        loading: true,
        content: "确定要删除该广告吗?",
        okText: "删除",
        async onOk() {
          try {
            let ids = [row.ID];
            await api.DeleteAdvert({ ids: ids });
            const index = self.resObj.DataList.findIndex(
              (item) => item.ID === row.ID
            );
            if (index !== -1) {
              self.resObj.DataList[index].OrderStatus = -99;
              self.resObj.DataList[index].StatusCN = "已删除";
            }
            self.$Message.success("删除成功");
            self.$Modal.remove();
            setTimeout(function () {
              self.loadList();
            }, 800);
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
  },
  computed: {
    positionList() {
      return this.params.Version
        ? this.AdvertPositionList.filter(
            (item) => item.Version === this.params.Version
          )
        : this.AdvertPositionList;
    },
    AdvertStatusEnum() {
      return bizEnum.AdvertStatusEnum;
    },
    AdvertPositionEnum() {
      return bizEnum.AdvertPositionEnum;
    },
    PositionVersionEnum() {
      return bizEnum.PositionVersionEnum;
    },
  },
  components: { layout, DetailDrawer, EditDetailDrawer },
};
</script>

<style lang="scss"></style>
